import moment from 'moment';
import { ActionType, getType } from "typesafe-actions";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { errorActions } from "../actions";
import { banksActions } from '../actions/banks.actions';
import { BwicBank } from '../types/banks/Bank';
import { BankDetailed } from '../types/banks/BankDetailed';
import { banksService } from '../services/banks.service';
import { ManagersTransaction } from '../types/banks/ManagersTransaction';
import { ManagersTransactionCountFilterValues } from '../types/banks/ManagersTransactionCountFilterValues';
import { arrayUtils } from '../utils/array.utils';
import { ManagersTransactionAggregated } from '../types/banks/ManagersTransactionAggregated';
import { compact, map, mapValues, pick, sumBy, toPairs, trim, values } from 'lodash';
import { TransactionType } from '../types/amr-pipeline/enums/TransactionType';
import { defaultFilterValues } from '../constants/banks/managerTransactionsFilter';
import { AppState } from '../types/state/AppState';
import { constants, roles, routes } from '../constants';
import { Company } from "../types/amr-pipeline/models/Company";
import { CompanyEvent } from '../types/banks/CompanyEvent';
import { BankSave } from '../types/banks/BankSave';
import { formatUtils, stringUtils } from '../utils';
import { companiesService } from '../services';
import { BrokerDealerCompanySlim } from '../types/company/BrokerDealerCompanySlim';
import { BrokerDealerContact } from '../types/amr-pipeline/models/BrokerDealerContact';
import { BrokerDealerSave } from '../types/banks/BrokerDealerSave';
import { RequestState } from '../constants/request-state';
import { user } from '../user';
import { combineBanks, isBrokerDealersOwnCompany } from '../utils/amr-pipeline.utils';
import { HeadOfTrading } from '../types/company/HeadOfTrading';
import { BankClientActivity, BankProfileView, BankSession, BankSessions } from '../types/amr-pipeline/models/BankSession';
import { history } from "../history";
import { BankAccessType } from '../types/amr-pipeline/enums/BankAccessType';
import { groupDocsWithAccessType, transformToTreeSelect } from '../utils/analytics.utils';
import { compareDates } from '../utils/compare.utils';
import { BankAnalytics } from '../types/banks/BankAnalytics';
import { BankSyndicateContacts } from '../types/banks/BankSyndicateContacts';
import { SubscriptionFeature } from '../types/billing/SubscriptionFeature';
import saveAs from "file-saver";
import { ContactType } from '../types/amr-pipeline/enums/ContactType';
import { amrCompaniesService } from '../services/amr-companies.service';

const UserAccessTypes = [
    BankAccessType.ContactsEventsTab,
    BankAccessType.PrimaryTab,
    BankAccessType.SecondaryTab,
];

function groupSessionsByAccessType(sessions: BankSession[]) {
    return sessions
        .reduce((acc: BankProfileView[], session: BankSession) => {
            const views = mapValues(session.tabsViews, x => x.length);
            const documents = groupDocsWithAccessType(session.documents);

            return [
                ...acc,
                {
                    ...session,
                    views,
                    documents,
                } as BankProfileView
            ];
        }, [])
        .sort((a, b) => compareDates(b.accessDateTime, a.accessDateTime));
}

function groupSessionsByDate(sessions: BankSession[]) {
    return sessions.reduce((acc: BankClientActivity[], session: BankSession) => {
        const grouped = toPairs(session.tabsViews);
        const documents = groupDocsWithAccessType(session.documents);

        return [
            ...acc,
            ...grouped.map(([accessType, entries]) => ({
                ...session,
                accessType,
                numberOfAccess: entries.length,
                documents,
            } as BankClientActivity)),
        ];
    }, []);
}

const MandatoryContactFields = [
    'firstName',
    'lastName',
    'email',
    'officePhoneNumber',
    'mobilePhoneNumber',
    'distributionListEmail',
];

const createEmptyContact = (primaryDesk: boolean): BrokerDealerSave => ({
    id: 0,
    primaryDesk,
    order: 0,
    firstName: undefined,
    lastName: undefined,
    email: '',
    officePhoneNumber: '',
    mobilePhoneNumber: '',
    distributionListEmail: '',
});

const createDistributionList = (email: string, primaryDesk: boolean): BrokerDealerContact => ({
    id: 0,
    primaryDesk,
    order: 0,
    type: ContactType.DistributionList,
    firstName: undefined,
    lastName: undefined,
    email,
    officePhoneNumber: '',
    mobilePhoneNumber: '',
});

const notEmptyEvent = (event: CompanyEvent) => !stringUtils.isHTMLEmpty(event.description);

const isContactEmpty = (contact?: BrokerDealerSave) => {
    // Grab only mandatory fields from an object
    // Compact them and see the length of resulting array
    const withNeededValues = pick(contact, MandatoryContactFields);
    return !compact(values(withNeededValues)).length;
};

const isHeadOfTradingEmpty = (headOfTrading?: HeadOfTrading) => {
    // Get values of all object properties and trim them
    // (there was a bug with strings full of whitespaces)
    // Compact and see length of resulting array
    return !compact(map(values(headOfTrading), trim)).length;
};

// Checks if all andatory fields of a contacts are empty,
// and if yes - returns a default empty contact, otherwise
// returns passed contact as is
const contactOrDefault = (contact?: BrokerDealerSave, primaryDesk = false) => {
    return isContactEmpty(contact) ? createEmptyContact(primaryDesk) : contact;
};

const splitIntoHeadAndDistributionList = (brokerDealer?: BrokerDealerSave) => {
    if (!brokerDealer) {
        return {}
    }

    const { distributionListEmail, ...headContact } = brokerDealer;

    const distributionList = distributionListEmail
        ? createDistributionList(distributionListEmail, true)
        : undefined;

    return {
        headContact: isContactEmpty(headContact)
            ? undefined
            : headContact,
        distributionList,
    }
}

const convertToBankSyndicateContacts = (
    contacts: BrokerDealerContact[],
    headOfDeskAndDistributionList?: BrokerDealerSave,
    headOfTradingAndDistributionList?: BrokerDealerSave
): BankSyndicateContacts => {
    const primary = contacts.filter(x => x.primaryDesk);
    const secondary = contacts.filter(x => !x.primaryDesk);

    const {
        headContact: headOfDesk,
        distributionList: primaryDistributionList
    } = splitIntoHeadAndDistributionList(headOfDeskAndDistributionList);

    const {
        headContact: headOfTrading,
        distributionList: secondaryDistributionList
    } = splitIntoHeadAndDistributionList(headOfTradingAndDistributionList);

    return {
        primaryContacts: {
            items: compact([...primary, headOfDesk, primaryDistributionList]),
            numberOfHiddenItems: 0,
        },
        secondaryContacts: {
            items: compact([...secondary, headOfTrading, secondaryDistributionList]),
            numberOfHiddenItems: 0,
        },
    };
};

function aggregateManagerTransactions(
    managerTransactions: ManagersTransaction[],
    filter: ManagersTransactionCountFilterValues = defaultFilterValues
) {
    const filtered = managerTransactions.filter(managerTransaction => {
        return managerTransaction.year === filter.year
            && (filter.currency !== constants.allListItem
                ? managerTransaction.currencyCode=== filter.currency
                : true)
            && (filter.collateralType !== constants.allListItem
                ? managerTransaction.collateralType === filter.collateralType
                : true
            );
    });

    const groupedManager = arrayUtils.groupBy(filtered, x => x.companyReferenceName);

    return Array.from(groupedManager.values()).reduce((acc: ManagersTransactionAggregated[], group: ManagersTransaction[]) => {
        const [firstEntry] = group;

        const newIssueCount = sumBy(group.filter(x => x.transactionType === TransactionType.newIssue), x => x.count);
        const refinancingCount = sumBy(group.filter(x => x.transactionType === TransactionType.refi), x => x.count);
        const resetCount = sumBy(group.filter(x => x.transactionType === TransactionType.reset), x => x.count);

        return [
            ...acc,
            {
                collateralManager: firstEntry.companyLegalName,
                companyReferenceName: firstEntry.companyReferenceName,
                newIssueCount,
                refinancingCount,
                resetCount,
                totalCount: newIssueCount + refinancingCount + resetCount,
            } as ManagersTransactionAggregated
        ]
    }, []);
}

function* getSyndicateContacts(referenceName: string, canViewContactsAndEvents: boolean) {
    let syndicateContacts: BankSyndicateContacts;
    let brokerDealer: BrokerDealerCompanySlim;

    [
        syndicateContacts,
        brokerDealer,
    ] = yield all([
        call(banksService.getSyndicateContacts, referenceName),
        call(companiesService.getBrokerDealer, referenceName),
    ]);

    // Just in case check that Head Of Trading contact is empty.
    // Potentially the object can be defined, but with empty fields
    const headOfTrading: BrokerDealerContact | undefined = !isHeadOfTradingEmpty(brokerDealer?.headOfTrading) ? {
        id: 0,
        title: 'Head of Trading',
        firstName: brokerDealer.headOfTrading.firstName,
        lastName: brokerDealer.headOfTrading.lastName,
        email: brokerDealer.headOfTrading.email,
        officePhoneNumber: trim(brokerDealer.headOfTrading.office),
        mobilePhoneNumber: trim(brokerDealer.headOfTrading.mobile),
        primaryDesk: false,
        headOfTrading: true,
        order: 0,
    } : undefined;

    const secondaryDistributionList: BrokerDealerContact | undefined = brokerDealer?.distributionList ? {
        id: 0,
        title: 'Distribution List',
        email: brokerDealer.distributionList,
        officePhoneNumber: '',
        mobilePhoneNumber: '',
        primaryDesk: false,
        headOfTrading: true,
        type: ContactType.DistributionList,
        order: 0,
    } : undefined;

    const bwicContacts = compact([
        headOfTrading,
        secondaryDistributionList,
    ]);

    const mergedContacts: BankSyndicateContacts = {
        ...syndicateContacts,
        // Merge head of trading and secondary desk distribution list into secondary list
        secondaryContacts: {
            items: [
                ...syndicateContacts.secondaryContacts.items,
                ...bwicContacts
            ],
            // When access is limited, number of hidden items is a summary
            // of number of hidden items from AMR and BWIC contacts - 1
            // (only two contacts from BWIC, so one should be hidden), but not
            // less than 0
            numberOfHiddenItems: canViewContactsAndEvents
                ? syndicateContacts.secondaryContacts.numberOfHiddenItems
                : syndicateContacts.secondaryContacts.numberOfHiddenItems + +(brokerDealer.distributionListExists || false),
        }
    };

    return mergedContacts;
}

function* watchGetBanks(action: ActionType<typeof banksActions.getBanks>) {
    const { userCompanyReferenceName } = action.payload;

    try {
        const [amrData, bwicData]: [amrData: Company[], bwicData: BwicBank[]] =
            yield all([
                call(amrCompaniesService.getBanksList),
                call(banksService.getBwicBanksList),
            ]);

        const banks = combineBanks(amrData, bwicData, userCompanyReferenceName);
        yield put(banksActions.getBanksResult(banks));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchGetBankDetails(action: ActionType<typeof banksActions.getBankDetails>) {
    try {
        const { referenceName } = action.payload;

        const userCompany: Company = yield select((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);

        let bank: BankDetailed;
        let syndicateContacts: BankSyndicateContacts;
        let companyEvents: CompanyEvent[];

        const isNotBrokerDealer = user.hasRoles(...roles.getAllRolesExclude(...roles.bd()));
        const ownCompany = isBrokerDealersOwnCompany({ referenceName }, userCompany);
        const hasEventsFeature = user.hasFeatures(SubscriptionFeature.DealerProfileBankEvents);

        const canViewContactsAndEvents = ownCompany || (isNotBrokerDealer && hasEventsFeature);

        [
            bank,
            syndicateContacts,
            companyEvents,
        ] = yield all([
            call(banksService.getBankDetails, referenceName),
            call(getSyndicateContacts, referenceName, canViewContactsAndEvents),
            canViewContactsAndEvents ? call(banksService.getEvents, referenceName) : [],
        ]);

        const managerTransactions = aggregateManagerTransactions(bank.managersTransactions);

        yield put(banksActions.getBankDetailsResult(
            referenceName,
            bank,
            managerTransactions,
            syndicateContacts,
            companyEvents,
        ));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchManagersTransactionCountFilter(action: ActionType<typeof banksActions.managersTransactionCountFilter>) {
    try {
        const managerTransactions: ManagersTransaction[] = yield select(
            (state: AppState) => state.banks.selectedBank?.details?.managersTransactions
        );

        const managerTransactionsAggregated = aggregateManagerTransactions(managerTransactions, action.payload);

        yield put(banksActions.managersTransactionCountFilterResult(managerTransactionsAggregated));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchExportTransactionCountRequest(action: ActionType<typeof banksActions.exportTransactionCountRequest>) {
    const { legalName, referenceName, params } = action.payload;

    try {
        const fileName = `${legalName} Managers Transaction Count - ${moment().format(constants.dateFormat)}.xlsx`;
        const file: { blob: Blob } = yield call(banksService.exportBankManagersTrans, referenceName, params);
        saveAs(file.blob, fileName);
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(banksActions.exportTransactionCountResponse());
    }
}

function* watchUpdateBank(action: ActionType<typeof banksActions.updateBank>) {
    try {
        const { referenceName, formData } = action.payload;

        const { dataItems, isValid } = yield select((state: AppState) => state.grid);

        const events = formData.events.filter(notEmptyEvent).reverse();

        const contacts = (dataItems as any[]).reduce((acc: BrokerDealerContact[], contact: any, index: number) => {
            return contact.draft ? acc : [
                ...acc,
                {
                    ...contact,
                    order: index + 1,
                    linkedIn: formatUtils.formatUrlWithProtocol(contact.linkedIn),
                } as BrokerDealerContact
            ]
        }, []);

        if (!isValid && contacts.length) {
            yield put(banksActions.updateBankResult(RequestState.failure));
            return;
        }

        const headOfDesk = contactOrDefault(formData.headOfDesk, true);
        const headOfTrading = contactOrDefault(formData.headOfTrading);

        const bankToSave: BankSave = {
            events,
            contacts,
            headOfDesk,
            headOfTrading,
        };

        yield call(banksService.updateBank, referenceName, bankToSave);

        const syndicateContacts = convertToBankSyndicateContacts(contacts, headOfDesk, headOfTrading)

        const companyEvents: CompanyEvent[] = yield call(banksService.getEvents, referenceName);

        yield put(banksActions.updateBankResult(RequestState.success, {
            syndicateContacts,
            events: companyEvents
        }));

        yield call(history.push, routes.manageBanksUrl(referenceName));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchLogUserActivity(action: ActionType<typeof banksActions.logUserActivity>) {
    const { companyReferenceName, accessType } = action.payload;
    try {
        yield call(banksService.logUserActivity, companyReferenceName, accessType);
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}


function* watchAnalyticsInit(action: ActionType<typeof banksActions.analyticsInit>) {
    try {
        const { companyReferenceName } = action.payload;

        const { items, numberOfHiddenItems }: BankSessions = yield call(banksService.getProfileViewHistory, companyReferenceName);
        const analytics: BankAnalytics = yield call(banksService.getAnalytics, companyReferenceName);

        const profileViews = groupSessionsByDate(items);

        const users = transformToTreeSelect(profileViews, UserAccessTypes);

        yield put(banksActions.analyticsInitResponse({ users, analytics, numberOfHiddenItems }));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchClientsActivityRequest(action: ActionType<typeof banksActions.clientsActivityRequest>) {
    try {
        const { companyReferenceName, startDate, endDate } = action.payload;

        const { items, numberOfHiddenItems }: BankSessions = yield call(
            banksService.getProfileViewHistory,
            companyReferenceName,
            startDate ? moment(startDate).startOf('day').toDate() : undefined,
            endDate ? moment(endDate).endOf('day').toDate() : undefined,
        );

        const bankClientsActivity = groupSessionsByDate(items)
            .filter(x => UserAccessTypes.includes(x.accessType));

        yield put(banksActions.clientsActivityResponse(bankClientsActivity, numberOfHiddenItems));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchProfileViewHistoryRequest(action: ActionType<typeof banksActions.profileViewHistoryRequest>) {
    try {
        const { companyReferenceName, startDate, endDate } = action.payload;

        const alignedStartDate = startDate ? moment(startDate).startOf('day').toDate() : undefined;
        const alignedEndDate = endDate ? moment(endDate).endOf('day').toDate() : undefined;

        const { items, numberOfHiddenItems }: BankSessions = yield call(
            banksService.getProfileViewHistory,
            companyReferenceName,
            alignedStartDate,
            alignedEndDate
        );

        const profileViewHistory = groupSessionsByAccessType(items);

        yield put(banksActions.profileViewHistoryResponse(profileViewHistory, numberOfHiddenItems));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchProfileViewHistoryFiltrByBar(action: ActionType<typeof banksActions.profileViewHistoryFilterByBar>) {
    const { companyReferenceName, date } = action.payload;

    yield put(
        banksActions.profileViewHistoryRequest(
            companyReferenceName,
            date,
            date
        )
    );
}

export function* watchBanks() {
    yield takeLatest(getType(banksActions.getBanks), watchGetBanks);
    yield takeLatest(getType(banksActions.getBankDetails), watchGetBankDetails);
    yield takeLatest(getType(banksActions.managersTransactionCountFilter), watchManagersTransactionCountFilter);
    yield takeLatest(getType(banksActions.updateBank), watchUpdateBank);
    yield takeLatest(getType(banksActions.logUserActivity), watchLogUserActivity);
    yield takeLatest(getType(banksActions.analyticsInit), watchAnalyticsInit);
    yield takeLatest(getType(banksActions.clientsActivityRequest), watchClientsActivityRequest);
    yield takeLatest(getType(banksActions.profileViewHistoryRequest), watchProfileViewHistoryRequest);
    yield takeLatest(getType(banksActions.profileViewHistoryFilterByBar), watchProfileViewHistoryFiltrByBar);
    yield takeLatest(getType(banksActions.exportTransactionCountRequest), watchExportTransactionCountRequest);
}
